var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-navbar", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var isMobile = ref.isMobile
          var isTouch = ref.isTouch
          var sidebar = ref.sidebar
          var meta = ref.meta
          var impersonating = ref.impersonating
          var toggleSidebar = ref.toggleSidebar
          var canAccessTasks = ref.canAccessTasks
          var items = ref.items
          return [
            _c(
              "nav",
              { staticClass: "navbar app-navbar is-fixed-top brand_color" },
              [
                _c("div", { staticClass: "navbar-brand" }, [
                  _c(
                    "a",
                    {
                      staticClass: "navbar-item",
                      on: {
                        click: function($event) {
                          return toggleSidebar(isTouch)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon" },
                        [
                          _c("fa", {
                            class: {
                              rotate: !sidebar.isExpanded || !sidebar.isVisible
                            },
                            attrs: { icon: "bars" }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "a",
                    { staticClass: "navbar-item logo", attrs: { href: "#" } },
                    [
                      _c("figure", { staticClass: "image" }, [
                        _c("img", {
                          staticClass: "calgary_logo",
                          attrs: { src: "/images/logo.png" }
                        })
                      ])
                    ]
                  ),
                  impersonating
                    ? _c("div", { staticClass: "navbar-item" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.i18n("Stop Impersonating"),
                                expression: "i18n('Stop Impersonating')"
                              }
                            ],
                            staticClass: "button is-small is-warning",
                            on: {
                              click: function($event) {
                                return _vm.$root.$emit("stop-impersonating")
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon is-small" },
                              [_c("fa", { attrs: { icon: "user" } })],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "icon is-small" },
                              [_c("fa", { attrs: { icon: "times" } })],
                              1
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "navbar-menu" }, [
                  _c(
                    "div",
                    { staticClass: "navbar-end" },
                    [
                      _c("app-update"),
                      _vm._l(items, function(item) {
                        return _c(item, { key: item, tag: "component" })
                      }),
                      _c("settings-control")
                    ],
                    2
                  )
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }