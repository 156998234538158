var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-default", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var appState = ref.appState
          var lightsOff = ref.lightsOff
          var bookmarks = ref.bookmarks
          var sidebar = ref.sidebar
          var settings = ref.settings
          var rtl = ref.rtl
          var slideIn = ref.slideIn
          var slideOut = ref.slideOut
          return [
            _c(
              "div",
              { staticClass: "app-main", class: { "lights-off": lightsOff } },
              [
                _c("navbar-refresh"),
                _c(
                  "slide-down",
                  [
                    bookmarks
                      ? _c("bookmarks", {
                          class: [
                            { "with-sidebar": sidebar.isVisible },
                            { "sidebar-collapsed": !sidebar.isExpanded }
                          ]
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "horizontal-slide",
                  { attrs: { rtl: rtl } },
                  [
                    sidebar.isVisible
                      ? _c("sidebar", {
                          class: { "is-collapsed": !sidebar.isExpanded }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "section",
                  {
                    staticClass: "main-content",
                    class: [
                      sidebar.isExpanded ? "is-expanded" : "is-collapsed",
                      { "with-bookmarks": bookmarks }
                    ],
                    attrs: { "oracast.seven.lakes": "" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "wrapper page-content" },
                      [
                        _c("page-header", { key: _vm.$route.path }),
                        appState ? _c("main-router") : _vm._e()
                      ],
                      1
                    )
                  ]
                ),
                _c("scroll-to-top", {
                  attrs: { type: "is-medium is-primary is-raised" }
                }),
                _c("settings", {
                  staticClass: "animated",
                  class: settings.isVisible ? slideIn : slideOut
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }