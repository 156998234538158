<template>
    <core-navbar>
        <template v-slot:default="{
            isMobile, isTouch, sidebar, meta, impersonating,
            toggleSidebar, canAccessTasks, items,
        }">
            <nav class="navbar app-navbar is-fixed-top brand_color">
                <div class="navbar-brand">
                    <a class="navbar-item"
                        @click="toggleSidebar(isTouch)">
                        <span class="icon">
                            <fa icon="bars"
                                :class="{ 'rotate': !sidebar.isExpanded || !sidebar.isVisible }"/>
                        </span>
                    </a>
                    <a class="navbar-item logo" href="#">
                        <figure class="image">
                            <img src="/images/logo.png" class="calgary_logo">
                        </figure>
                    </a>
                    <div class="navbar-item"
                        v-if="impersonating">
                        <a v-tooltip="i18n('Stop Impersonating')"
                            class="button is-small is-warning"
                            @click="$root.$emit('stop-impersonating')">
                            <span class="icon is-small">
                                <fa icon="user"/>
                            </span>
                            <span class="icon is-small">
                                <fa icon="times"/>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="navbar-menu">
                    <div class="navbar-end">
                        <app-update/>
                        <component v-for="item in items"
                            :is="item"
                            :key="item"/>
                        <settings-control/>
                    </div>
                </div>
            </nav>
        </template>
    </core-navbar>
</template>

<script>
import { VTooltip } from 'v-tooltip';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars, faCode, faUser, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import CoreNavbar from '@core/core/components/navbar/Navbar.vue';
import AppUpdate from '@core/bulma/components/navbar/AppUpdate.vue';
import SettingsControl from '@core/bulma/components/navbar/SettingsControl.vue';

library.add(faBars, faCode, faUser, faTimes);

export default {
    name: 'Navbar',

    directives: { tooltip: VTooltip },

    components: {
        AppUpdate,
        CoreNavbar,
        SettingsControl,
    },

    inject: ['i18n'],
};
</script>

<style lang="scss">
@import '@enso-ui/themes/bulma/variables';

.navbar {
    height: $navbar-height;
    z-index: 3;
    -webkit-box-shadow: 0 1px 1px hsla(0, 0%, 4%, .35);
    box-shadow: 0 1px 1px hsla(0, 0%, 4%, .35);

    .fa-bars {
        transition: transform .300s;

        &.rotate {
            transform: rotate(90deg);
        }
    }
}

</style>
