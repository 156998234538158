const AccountShow = () => import('@pages/accounts/Show.vue');

export default {
    name: 'accounts.show',
    path: ':account',
    component: AccountShow,
    meta: {
        breadcrumb: 'show',
        title: 'Account Profile',
        description: 'View the available details for this Account',
        icon: 'user',
    },
};
