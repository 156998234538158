import routeImporter from '@core/modules/importers/routeImporter';

const pinRoutes = routeImporter(require.context('./pins', false, /.*\.js$/));
const RouterView = () => import('@core/bulma/pages/Router.vue');

export default {
    path: '/pins',
    component: RouterView,
    meta: {
        breadcrumb: 'pins',
        route: 'pins.index',
    },
    children: pinRoutes,
};
