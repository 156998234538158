const AccountIndex = () => import('@pages/accounts/Index.vue');

export default {
    name: 'accounts.index',
    path: '',
    component: AccountIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Accounts',
        description: 'The Account Index is where you can view and control available Accounts',
        icon: 'users',
    },
};
