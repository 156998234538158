const MediaEdit = () => import('@pages/locations/medias/Edit.vue');

export default {
    name: 'locations.medias.edit',
    path: ':media/edit',
    component: MediaEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Media Card',
        description: 'The Edit Location will allow you to update details for this Location',
        icon: 'pencil',
    },
};
