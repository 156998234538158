const MediasIndex = () => import('@pages/locations/medias/Index.vue');

export default {
    name: 'locations.medias.index',
    path: '',
    component: MediasIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Manage Media Cards',
        description: 'The Media is where you can view and control Media',
        icon: 'photo-video',
    },
};
