const PinEdit = () => import('@pages/pins/Edit.vue');

export default {
    name: 'pins.edit',
    path: ':pin/edit',
    component: PinEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Manage Pin Icons',
        description: 'The Edit Pin will allow you to update details for this Pin',
        icon: 'pencil',
    },
};
