const CompanyIndex = () => import('@pages/companies/Index.vue');

export default {
    name: 'companies.index',
    path: '',
    component: CompanyIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Companies',
        description: 'The Company Index is where you can view and control available Companies',
        icon: 'building',
    },
};
