var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-page-header", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var pageTitle = ref.pageTitle
          return [
            _c("div", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left is-hidden-mobile" }, [
                _c("div", { staticClass: "level-item" }, [
                  _c(
                    "p",
                    { staticClass: "title is-4 page-title animated fadeIn" },
                    [_vm._v(" " + _vm._s(_vm.i18n(pageTitle)) + " ")]
                  )
                ])
              ]),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [_c("breadcrumbs", { staticClass: "animated fadeIn" })],
                  1
                )
              ])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }