const CompanyShow = () => import('@pages/companies/Show.vue');

export default {
    name: 'companies.show',
    path: ':company',
    component: CompanyShow,
    meta: {
        breadcrumb: 'show',
        title: 'Company Profile',
        description: 'View the available details for this Account',
        icon: 'building',
    },
};
