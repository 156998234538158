const PinCreate = () => import('@pages/pins/Create.vue');

export default {
    name: 'pins.create',
    path: 'create',
    component: PinCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Pin Icons',
        description: 'The Pin system will allow you to add a new Pin',
        icon: 'plus',
    },
};
