const LocationEdit = () => import('@pages/locations/Edit.vue');

export default {
    name: 'locations.edit',
    path: ':location/edit',
    component: LocationEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Location',
        description: 'The Edit Location will allow you to update details for this Location',
        icon: 'pencil',
    },
};
