<template>
    <core-auth v-slot:default="{ guestState }">
        <section class="auth hero is-fullheight is-primary">
            <div class="auth-body hero-body">
                <div class="container">
                    <router v-if="guestState"/>
                </div>
            </div>
        </section>
    </core-auth>
</template>

<script>
import CoreAuth from '@layouts/core/Auth.vue';
import Router from '@enso-ui/ui/src/bulma/pages/Router.vue';

export default {
    name: 'Auth',

    components: { CoreAuth, Router },
};
</script>
