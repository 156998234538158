var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-auth", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var guestState = ref.guestState
          return [
            _c(
              "section",
              { staticClass: "auth hero is-fullheight is-primary" },
              [
                _c("div", { staticClass: "auth-body hero-body" }, [
                  _c(
                    "div",
                    { staticClass: "container" },
                    [guestState ? _c("router") : _vm._e()],
                    1
                  )
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }