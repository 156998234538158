var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-sidebar-state", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var bindings = ref.bindings
          var events = ref.events
          return [
            _c("div", { staticClass: "level is-mobile" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("div", { staticClass: "level-item" }, [
                  _vm._v(" " + _vm._s(_vm.i18n("Expanded Sidebar")) + " ")
                ])
              ]),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [
                    _c(
                      "vue-switch",
                      _vm._g(
                        _vm._b(
                          { staticClass: "is-medium" },
                          "vue-switch",
                          bindings,
                          false
                        ),
                        events
                      )
                    )
                  ],
                  1
                )
              ])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }