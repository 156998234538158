const LocationIndex = () => import('@pages/locations/Index.vue');

export default {
    name: 'locations.index',
    path: '',
    component: LocationIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Locations',
        description: 'The Locations Index is where you can view and control available Locations',
    },
};
