const AccountEdit = () => import('@pages/accounts/Edit.vue');

export default {
    name: 'accounts.edit',
    path: ':account/edit',
    component: AccountEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Account',
        description: 'The Edit Account system will allow you to update details for this Account',
        icon: 'pencil',
    },
};
