import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import Root from '@components/ui/Root.vue';
import App from '@enso-ui/ui/src/core/app';
import router from '@enso-ui/ui/src/core/services/router';
import store from '@enso-ui/ui/src/core/services/store';
import '@enso-ui/ui/src/modules';
import './app';
import '../sass/enso.scss';
import './localState';
import Buefy from 'buefy';
import './plugins/gmap';
// import LoadScript from 'vue-plugin-load-script';

Vue.config.productionTip = false;

Vue.prototype.$displayImage = image => {
    if (!image) {
        return;
    }

    // eslint-disable-next-line consistent-return
    return URL.createObjectURL(image);
};

// Vue.use(LoadScript);
Vue.use(Buefy, {
    defaultNoticeQueue: false,
    defaultIconPack: 'fas',
    defaultIconComponent: 'fa',
});

sync(store, router);

const vm = new Vue({
    router,
    store,
    ...Root,
}).$mount('#app');

App.boot(vm);
