<template>
    <core-sidebar>
        <template v-slot:default="{ menus }">
            <vue-aside class="sidebar no-scrollbars brand_color">
                <p class="menu-label">
                    {{ i18n("Navigation") }}
                </p>
                <menus class="enso-menu"
                    :menus="menus"/>
            </vue-aside>
        </template>
    </core-sidebar>
</template>

<script>
import Menus from '@enso-ui/menus/src/bulma/components/menu/Menus.vue';
import CoreSidebar from '@core/core/components/menu/Sidebar.vue';
import VueAside from '@core/bulma/components/VueAside.vue';

export default {
    name: 'Sidebar',

    components: { CoreSidebar, Menus, VueAside },

    inject: ['i18n'],
};
</script>

<style lang="scss">
    @import '@enso-ui/themes/bulma/variables';

    .aside.sidebar {
        overflow-y: auto;
        transition: width .5s;

        &.is-collapsed {
            width: $sidebar-collapsed-width;
        }
    }
</style>
