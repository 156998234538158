var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-sidebar", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var menus = ref.menus
          return [
            _c(
              "vue-aside",
              { staticClass: "sidebar no-scrollbars brand_color" },
              [
                _c("p", { staticClass: "menu-label" }, [
                  _vm._v(" " + _vm._s(_vm.i18n("Navigation")) + " ")
                ]),
                _c("menus", {
                  staticClass: "enso-menu",
                  attrs: { menus: menus }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }