var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-home", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var loading = ref.loading
          var showQuote = ref.showQuote
          var quote = ref.quote
          var hide = ref.hide
          return [
            _c("section", { staticClass: "hero is-fullheight is-primary" }, [
              _c("div", { staticClass: "hero-body" }, [
                _c(
                  "div",
                  { staticClass: "container has-text-centered" },
                  [
                    loading
                      ? _c("loader", {
                          attrs: {
                            transparent: "",
                            size: "large",
                            color: "#dbdbdb"
                          }
                        })
                      : showQuote
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "subtitle is-1 inspiring animated fadeInDown"
                            },
                            [_vm._v(" " + _vm._s(quote) + " ")]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                { name: "focus", rawName: "v-focus" }
                              ],
                              staticClass:
                                "animated fadeInRightBig button is-outlined",
                              on: { click: hide }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.i18n("Enter the application")) +
                                  " "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }