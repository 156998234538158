const LocationCreate = () => import('@pages/locations/Create.vue');

export default {
    name: 'locations.create',
    path: 'create',
    component: LocationCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Location',
        description: 'The Location system will allow you to add a new Location',
        icon: 'plus',
    },
};
