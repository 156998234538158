import store from '@enso-ui/ui/src/core/services/store';

const Login = () => import('@pages/auth/Login.vue');
const Email = () => import('@pages/auth/password/Email.vue');
const Reset = () => import('@pages/auth/password/Reset.vue');

const Register = () => import('@pages/auth/Register.vue');
const RegisterAccount = () => import('@pages/auth/registration/RegisterAccount.vue');
const RegisterCompany = () => import('@pages/auth/registration/RegisterCompany.vue');

const redirect = (to, from, next) => {
    if (store.state.auth.isAuth) {
        next({ path: '/' });
    } else {
        next();
    }
};

export default [{
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: redirect,
    meta: {
        guestGuard: true,
        title: 'Login',
    },
}, {
    name: 'password.email',
    path: '/password/reset',
    component: Email,
    beforeEnter: redirect,
    meta: {
        guestGuard: true,
        title: 'Email Reset Link',
    },
}, {
    name: 'password.reset',
    path: '/password/reset/:token',
    component: Reset,
    beforeEnter: redirect,
    meta: {
        guestGuard: true,
        title: 'Reset Password',
    },
}, {
    name: 'register',
    path: '/register',
    component: Register,
    beforeEnter: redirect,
    meta: {
        guestGuard: true,
        title: 'Register Choice',
    },
}, {
    name: 'register.account',
    path: '/register/account',
    component: RegisterAccount,
    beforeEnter: redirect,
    meta: {
        guestGuard: true,
        title: 'Register Account',
    },
}, {
    name: 'register.company',
    path: '/register/company',
    component: RegisterCompany,
    beforeEnter: redirect,
    meta: {
        guestGuard: true,
        title: 'Register Company',
    },
}];
