const CompanyEdit = () => import('@pages/companies/Edit.vue');

export default {
    name: 'companies.edit',
    path: ':company/edit',
    component: CompanyEdit,
    meta: {
        breadcrumb: 'edit',
        title: 'Edit Company',
        description: 'The Edit Company system will allow you to update details for this Company',
        icon: 'pencil',
    },
};
