import routeImporter from '@core/modules/importers/routeImporter';

const locationRoutes = routeImporter(require.context('./locations', false, /.*\.js$/));
const locationMediaRoutes = routeImporter(require.context('./locations/medias', false, /.*\.js$/));
const RouterView = () => import('@core/bulma/pages/Router.vue');

export default [
    {
        path: '/locations',
        component: RouterView,
        meta: {
            breadcrumb: 'locations',
            route: 'locations.index',
        },
        children: locationRoutes,
    },
    {
        path: '/locations/:location/media',
        component: RouterView,
        meta: {
            breadcrumb: 'locations',
            route: 'locations.medias.index',
        },
        children: locationMediaRoutes,
    },
];
