const PinIndex = () => import('@pages/pins/Index.vue');

export default {
    name: 'pins.index',
    path: '',
    component: PinIndex,
    meta: {
        breadcrumb: 'index',
        title: 'Manage Pin Icons',
        description: 'The pins Index is where you can view and control available pins',
    },
};
