var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-app", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var isAuth = ref.isAuth
          var home = ref.home
          var direction = ref.direction
          return [
            _c(
              "div",
              { attrs: { id: "app", dir: direction } },
              [
                _c("document-title"),
                _c("loader"),
                _c(
                  "fade",
                  [!isAuth ? _c("auth") : home ? _c("home") : _c("default")],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }