var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-settings", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var multiLanguage = ref.multiLanguage
          var multiTheme = ref.multiTheme
          var meta = ref.meta
          var items = ref.items
          return [
            _c("vue-aside", { staticClass: "settings" }, [
              _c("p", { staticClass: "menu-label is-bold has-text-centered" }, [
                _vm._v(" " + _vm._s(_vm.i18n("Settings")) + " ")
              ]),
              _c(
                "ul",
                { staticClass: "menu-list" },
                [
                  _vm._l(_vm.filteredSettings(items), function(item) {
                    return _c(
                      "li",
                      { key: item, staticClass: "settings-item" },
                      [_c(item, { tag: "component" })],
                      1
                    )
                  }),
                  _c(
                    "li",
                    { staticClass: "settings-item" },
                    [_c("sidebar-state")],
                    1
                  )
                ],
                2
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }