import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faTachometerAlt, faCaravan, faTruck,
    faTruckMoving, faBoxes, faTablet, faTrailer,
    faRecycle, faTags, faPhone, faMapMarkerAlt, faTimesCircle,
    faPhotoVideo, faMapMarkedAlt, faLocationArrow, faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';

library.add(faTachometerAlt, faCaravan, faTruck,
    faTruckMoving, faBoxes, faTablet, faTrailer,
    faRecycle, faTags, faPhone, faMapMarkerAlt, faTimesCircle,
    faPhotoVideo, faMapMarkedAlt, faLocationArrow, faVolumeUp);
