const AccountCreate = () => import('@pages/accounts/Create.vue');

export default {
    name: 'accounts.create',
    path: 'create',
    component: AccountCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Account',
        description: 'The Create Account system will allow you to create a new Account',
        icon: 'plus',
    },
};
