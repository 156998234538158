const MediaCreate = () => import('@pages/locations/medias/Create.vue');

export default {
    name: 'locations.medias.create',
    path: 'create',
    component: MediaCreate,
    meta: {
        breadcrumb: 'create',
        title: 'Create Media Card',
        description: 'The Media system will allow you to add a new Media',
        icon: 'plus',
    },
};
// add option to delete one media
